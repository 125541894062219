<template>
  <section>
    <section class="es-loss-material">
      <VanSticky>
        <div class="page-head xa-cell">
          <Van-Button
            v-if="isApp"
            size="small"
            class="xa-txt-blue"
            @click="toScanMaterial"
            >扫码录入</Van-Button
          >
          <Van-Button
            size="small"
            class="xa-txt-blue"
            @click="showInputPopup = true"
            >手动输入</Van-Button
          >
          <Van-Button
            size="small"
            v-if="needSelectSn"
            class="xa-txt-blue"
            @click="gotoSelectSn"
            >选择序列号</Van-Button
          >
          <Van-Button size="small" class="xa-txt-blue" @click="gotoSelectCount"
            >选择批量</Van-Button
          >
        </div>
      </VanSticky>
      <component
        :is="listComponents"
        v-if="list.length > 0"
        :items="list"
        :onImgClick="onMaterialListImgClick"
        @sndelete="onSnDelete"
      />
      <div class="xa-view empty-box" v-else>
        <i class="iconfont icon-nodata"></i>
        <van-loading v-if="isLoading" size="24px" vertical
          >加载中...</van-loading
        >
        <p v-else>在上方选择录入坏件的方式</p>
      </div>
      <EnsureFootBtn
        :disabled="list.length == 0"
        :buttonText="buttonText"
        @submit="onSubmit"
      />
    </section>
    <van-popup v-model="showInputPopup" position="top">
      <div>
        <div class="xa-cell__box xa-txt-blue">手动录入序列号</div>
        <van-field
          v-model="curSn"
          placeholder="可通过换行或空格，输入多个"
          type="textarea"
          autosize
        >
          <van-button
            :disabled="!curSn"
            slot="button"
            size="small"
            type="info"
            @click="onSubmitInputSn"
            >确定</van-button
          >
        </van-field>
      </div>
    </van-popup>
    <div>
      <van-popup
        :value="isShowPopSelectCode"
        @input="onSelectCode()"
        position="bottom"
        round
        :style="{ height: '60%' }"
      >
        <div style="line-height:1.6;text-align:center;padding: 8px 0">
          该物料为可替换物料，请选择！
          <p style="font-size:12px;color:#666">{{ selectCodeSn }}</p>
        </div>
        <van-cell-group>
          <van-cell
            v-for="option in selectCodeOptions"
            :key="option.code"
            :title="option.name"
            :value="option.code"
            clickable
            @click="onSelectCode(option)"
          />
        </van-cell-group>
      </van-popup>
    </div>
  </section>
</template>
<script>
import { Button, Cell, CellGroup, Field, Loading, Sticky, Popup } from 'vant'
import basePage from '@/mixins/basePage'
import MaterialEditListWithImages from '@/components/material/MaterialEditList-WithImages'
import MaterialEditList from '@/components/material/MaterialEditList'
import {
  getLossMaterial,
  commitLossMaterial,
  checkMaterialInfo,
  saveLossSn,
  editCode,
  deleteSn,
  saveLossMateImages
} from '@/apis/material/'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import debounce from '@/utils/debounce'
import appController, { isApp } from '@/controllers/app-android'
class AsyncTask {
  constructor (tasks, fn) {
    this.tasks = tasks
    this.fn = fn
    this.resolve = null
  }
  async run () {
    const task = this.tasks.pop()
    if (task !== undefined) {
      await this.fn(task)
      this.run()
    } else {
      this.resolve()
    }
  }
  start () {
    return new Promise(resolve => {
      this.resolve = resolve
      this.run()
    })
  }
}
const CategoryType = {
  AUTH_AS: 'AUTH_AS',
  P_SERIAL: 'P_SERIAL',
  CARE_PLAN: 'CARE_PLAN', // 关怀计划
  GENERATOR: 'GENERATOR' // 关怀计划
}
export default {
  name: 'Cache_EnsureLossMaterial',
  mixins: [basePage],
  config: {
    title: '诊断-录入坏件'
  },
  components: {
    MaterialEditList,
    MaterialEditListWithImages,
    VanButton: Button,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanSticky: Sticky,
    VanPopup: Popup,
    VanField: Field,
    VanLoading: Loading,
    EnsureFootBtn
  },
  data () {
    return {
      isApp,
      isLoading: true,
      category: '', // 单据类型 【授权售后/AUTH_AS、P系列全保定损/P_SERIAL】
      needSelectSn: true, // 判断 是不是需要选择序列号
      buttonText: '',
      buttonAction: '_submitAction_submit',
      listComponents: 'MaterialEditList',
      showInputPopup: false,
      curSn: '',
      list: [],
      actionControl: null,
      query: null,
      startWatchList: false,
      lastEditCodeMap: {},
      isShowPopSelectCode: false,
      selectCodeOptions: [],
      selectCodeSn: ''
    }
  },
  watch: {
    list: {
      deep: true,
      handler () {
        if (!this.startWatchList) return
        this.updateList()
      }
    }
  },
  methods: {
    onSelectCode (option) {
      this.isShowPopSelectCode = false
      if (option === undefined) {
        this._isShowPopSelectCodeReject()
      } else {
        this._isShowPopSelectCodeResolve(option.code)
      }
    },
    showPopSelectCodes (sn, codes) {
      this.selectCodeSn = sn
      this.selectCodeOptions = codes
      return new Promise((resolve, reject) => {
        this._isShowPopSelectCodeResolve = resolve
        this._isShowPopSelectCodeReject = reject
        this.isShowPopSelectCode = true
      })
    },
    onMaterialListImgClick (item, imgKeys = 'images') {
      window.console.log(imgKeys)
      window.console.log(JSON.stringify(item))
      const guids = item[imgKeys]
      this.$router.push({
        name: 'loss-image-desc',
        params: {
          guids,
          cb: this.saveLossMateImages.bind(this, item.loss_mate_guid)
        }
      })
    },
    /**
     * 保存物料图片
     */
    saveLossMateImages (loss_mate_guid, images) {
      return this.$_syncSubmitData(
        saveLossMateImages({
          loss_mate_guid,
          images
        })
      )
    },
    /**
     * 因为该页面被不同的业务流需要，
     * 也因此底部提交按钮有不同的文案以及操作
     */
    getRightButtonText (needBackWhenSubmit, category) {
      if (needBackWhenSubmit) {
        this.buttonText = '确定并返回' // 【授权售后的定损】
        this.buttonAction = '_submitAction_goBack'
      } else if (
        [CategoryType.P_SERIAL, CategoryType.CARE_PLAN, CategoryType.GENERATOR].includes(category)
      ) {
        this.buttonText = '下一步' // 【P系列的定损】
        this.buttonAction = '_submitAction_next'
        this.listComponents = 'MaterialEditListWithImages'
      } else {
        this.buttonText = '确定' // 【默认】
        this.buttonAction = '_submitAction_submit'
      }
    },
    gotoSelectSn () {
      this.$router.push({
        name: 'material-select-sn',
        params: {
          control: this.actionControl
        },
        query: this.$route.query
      })
    },
    gotoSelectCount () {
      this.$router.push({
        name: 'material-select-count',
        params: {
          control: this.actionControl
        },
        query: this.$route.query
      })
    },
    async toScanMaterial () {
      const { url } = checkMaterialInfo()
      const sns = await appController.toScanMaterial({
        title: '请扫描物料二维码',
        url,
        params: this.query,
        paramsKey: 'serial_number'
      }) // Array<{sn,code,name}>
      await this.submitSelectSn(sns)
      this.initView()
    },
    checkSn (sn) {
      return new Promise(resolve => {
        let toast = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0
        })
        const done = () => {
          toast.close()
          resolve(1)
        }
        this.saveSn(sn.trim())
          .then(done)
          .catch(error => {
            this.$toast.fail({
              message: error.message || error.msg,
              onClose: done
            })
          })
      })
    },
    /**
     * 提交输入框提交的序列号
     */
    async onSubmitInputSn () {
      this.showInputPopup = false
      const sn = this.curSn.trim()
      this.curSn = ''
      const arrSn = sn.split(/\s/)
      for (let index = 0; index < arrSn.length; index++) {
        if (this.category === CategoryType.AUTH_AS||this.category === CategoryType.P_SERIAL) {
          // 【授权售后】单要支持可替换物料
          await this.saveDiffCodeSn(arrSn[index])
        } else {
          await this.checkSn(arrSn[index])
        }
      }
      this.initView()
    },
    async onSnDelete (itemIndex, sn) {
      this.startWatchList = false
      await this.$_submitDataWidthUi(async () => {
        let item = this.list[itemIndex]
        await this.$_request(
          deleteSn({
            ...this.query,
            code: item.code,
            serial_number: sn
          })
        )
      }, '确定要移除该序列号' + sn)
      this.initView()
    },
    updateList: debounce(async function () {
      await this.submitSelectedCount(this.list)
      this.initView()
    }, 500),
    // 提交选中的批量物料
    async submitSelectedCount (list) {
      let curEditCodeMap = {}
      const code_counts = list
        .filter(item => {
          curEditCodeMap[item.code] = item.count
          return this.lastEditCodeMap[item.code] !== item.count
        })
        .map(item => {
          return {
            code: item.code,
            count: item.count,
            serial_numbers: item.serial_numbers
          }
        })
      if (code_counts.length === 0) return
      await this.$_syncSubmitData(
        editCode({
          ...this.query,
          add: 0,
          code_counts
        })
      )
      this.lastEditCodeMap = curEditCodeMap
    },
    // 保存可能有替换物料的场景
    async saveDiffCodeSn (sn) {
      let toast
      let code
      try {
        toast = this.$toast.loading({
          message: '请求中...',
          forbidClick: false,
          duration: 0
        })
        const params = {
          ...this.query,
          serial_number: sn
        }
        const codes = await this.$_request(checkMaterialInfo(params))
        const needPop = Array.isArray(codes) && codes.length > 1
        if (needPop) {
          toast.close()
          try {
            code = await this.showPopSelectCodes(sn, codes)
          } catch (error) {
            return
          }
          toast = this.$toast.loading({
            message: '请求中...',
            forbidClick: false,
            duration: 0
          })
        } else if (codes.length === 1) {
          code = codes[0].code
        }
        await this.$_request(
          saveLossSn({
            ...params,
            code
          })
        )
        return 1
      } catch (error) {
        toast.close()
        await this.$dialog.alert({
          message: error.message || error.msg
        })
      } finally {
        toast.close()
      }
    },
    async saveSn (sn, code) {
      let params = {
        ...this.query,
        serial_number: sn
      }
      if (code) {
        params.code = code
      }
      await this.$_request(checkMaterialInfo(params))
      await this.$_request(saveLossSn(params))
    },
    async onSeletorSubmit (value) {
      this.startWatchList = false
      switch (this.$route.path) {
        case '/ensure-loss-material/select-sn':
          await this.submitSelectSn(this.getSelectedSn(value))
          break
        case '/ensure-loss-material/select-count':
          await this.submitSelectedCount(value)
          break
        default:
          break
      }
      this.$router.go(-1)
    },
    // 挑选出选中的sn
    getSelectedSn (list) {
      return list.reduce((result, item) => {
        item.serial_numbers.forEach(sn => {
          result.push({
            code: item.code,
            sn
          })
        })
        return result
      }, [])
    },
    // 提交选中的序列号
    async submitSelectSn (selectedSns) {
      // 过滤出已经录入的
      selectedSns = selectedSns.filter(
        snItem =>
          !this.list.find(item => item.serial_numbers.indexOf(snItem.sn) > -1)
      )
      const asyncTaks = new AsyncTask(selectedSns, snItem => {
        return this.$_syncSubmitData(this.saveSn(snItem.sn, snItem.code))
      })
      await asyncTaks.start()
    },
    async submitData () {
      const { href } = await this.$_submitDataWidthUi(
        commitLossMaterial(this.query)
      )
      this.$router.go(-1)
      setTimeout(() => {
        href && this.$_handleCfgAction({ href })
      }, 300)
    },
    async onSubmit () {
      this[this.buttonAction]()
    },
    async _submitAction_goBack () {
      return this.$router.go(-1)
    },
    async _submitAction_submit () {
      this.$_submitDataWidthUi(this.submitData, '确定提交定损结果？')
    },
    async _submitAction_next () {
      if (this.list.find(item => !item.images || item.images.length < 2)) {
        this.$dialog.alert({
          message: '每类物料最少上传两张照片'
        })
        return
      }
      this.$router.push({
        path: '/ensure/loss-verify',
        query: this.query
      })
    },
    async initView () {
      try {
        const { needBackWhenSubmit, needSelectSn } = this.$route.query
        this.needSelectSn = needSelectSn + '' !== '0'
        this.needBackWhenSubmit = needBackWhenSubmit + '' === '1'
        this.startWatchList = false
        const { data } = this.$_extractRequestInfoFormRoute()
        this.query = this.query || data
        const {
          materials,
          serial_number,
          category
        } = await this.$_fetchDataWidthUi(getLossMaterial(this.query))
        this.category = category
        // 只要是关怀服务的，都不选择需要序列号
        if (this.category === 'CARE_PLAN') {
          this.needSelectSn = false
        }else if (this.category === 'GENERATOR') {// 类型为发电机的，都不选择需要序列号
          this.needSelectSn = false
        }
        this.getRightButtonText(this.needBackWhenSubmit, category)
        this.list = materials
        this.actionControl = () => {
          return {
            getQuery () {
              return {
                ...data,
                serial_number
              }
            },
            submit: this.onSeletorSubmit.bind(this)
          }
        }
        setTimeout(() => {
          this.startWatchList = true
        }, 1000)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }
  },
  mounted () {
    this.initView()
  },
  activated () {
    if (this.isLoading === false) this.initView()
  }
}
</script>
<style lang="scss">
.es-loss-material {
  padding-bottom: 80px;
  .page-head {
    padding: 10px 15px;
    justify-content: space-between;
    background-color: $default-page-color;
  }
  .empty-box {
    height: 50vh;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 80px;
      margin-bottom: 16px;
    }
  }
}
#router-view {
  background-color: $default-page-color;
  min-height: 100vh;
}
</style>
