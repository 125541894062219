<template>
  <van-submit-bar
    :disabled="disabled"
    :button-text="buttonText"
    @submit="onSubmit"
    button-type="info"
    label="label"
  >
    <div class="xa-flex" style="padding-left:15px" @click="onLabelClick">
      <span
        class="es-cart-icon iconfont xa-txt-18"
        :class="disabled ? 'icon-cart-Empty' : 'icon-cart-full active'"
      ></span>
      {{ label }}
    </div>
  </van-submit-bar>
</template>
<script>
import { SubmitBar } from 'vant'
export default {
  components: {
    VanSubmitBar: SubmitBar
  },
  props: {
    disabled: {
      type: Boolean,
      default: true
    },
    label: String,
    buttonText: {
      type: String,
      default: '确定'
    }
  },
  methods: {
    onLabelClick() {
      !this.disabled && this.$emit('clickLabel')
    },
    onSubmit() {
      this.$emit('submit')
    }
  }
}
</script>
<style lang="scss" scoped>
.es-cart-icon {
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.5 ease-in-out;
}
.es-cart-icon {
  &.active {
    background-color: $color-blue;
    color: #fff;
  }
}
</style>
