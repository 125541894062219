<template>
  <div class="ensure-material-edit__list">
    <div
      v-for="(item, index) in items"
      :key="index + item.code"
      class="xa-line__bottom"
    >
      <materialInputItem v-if="item.is_single == 0" :item="item">
        <div
          class="xa-txt-center img-box xa-txt-12"
          :class="item.images.length ? 'xa-color-info' : 'xa-color-warning'"
          @click.stop="onImgClick(item)"
        >
          <icon v-if="item.images.length == 0" name="plus" />
          <span v-else>{{ item.images.length }}</span>
          <div>照片</div>
        </div>
      </materialInputItem>
      <materialSnItem
        v-else
        :item="item"
        @delete="onSnDelete(index, ...arguments)"
      >
        <div
          class="xa-txt-center img-box xa-txt-12"
          :class="item.images.length ? 'xa-color-info' : 'xa-color-warning'"
          @click.stop="onImgClick(item)"
        >
          <icon v-if="item.images.length == 0" name="plus" />
          <span v-else>{{ item.images.length }}</span>
          <div>照片</div>
        </div>
      </materialSnItem>
    </div>
    <div class="ensure-material-edit__foot">- 共{{ items.length }}条 -</div>
  </div>
</template>
<script>
import materialInputItem from '@/components/material/MaterialInputItem'
import materialSnItem from '@/components/material/MaterialSnItem'
import { Icon } from 'vant'
export default {
  components: {
    materialSnItem,
    materialInputItem,
    Icon
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    onImgClick: {
      type: Function,
      default: function(item) {
        window.console.log(item)
      }
    }
  },
  methods: {
    onSnDelete(itemIndex, sn) {
      this.$emit('sndelete', itemIndex, sn)
    }
  }
}
</script>
<style lang="scss">
.ensure-material-edit__list {
  .img-box {
    width: 40px;
    margin-left: 8px;
    border-left: 1px solid #e4e4e4;
  }
  .ensure-material-edit__foot {
    text-align: center;
    color: #999;
    font-size: 12px;
    padding: 8px 0;
  }
}
</style>
