<template>
  <section class="es-select-material-count" ref="container">
    <van-search
      v-model="keyword"
      placeholder="请输入搜索关键词"
      show-action
      @search="onSearch"
      @cancel="onCancel"
    />
    <van-sticky :container="container">
      <van-tabs v-model="tabActive">
        <van-tab v-for="item in tabs" :title="item.name" :key="item.name">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-list
      v-model="isListLoadingMore"
      :finished="isListLoadFinished"
      :finished-text="listFinishedText"
      :immediate-check="false"
      @load="onListLoadMore"
    >
      <MaterialEditList :items="waitForSelectList" />
    </van-list>
    <EnsureSubmitBar
      :disabled="selectResultList.length === 0"
      :label="submitBarLabel"
      @submit="onSubmit"
      @clickLabel="onClickLabel"
    />
    <van-popup
      v-model="showPopup"
      position="bottom"
      :style="{ height: '60%' }"
      round
    >
      <div class="xa-txt-12 pop-tip">
        如设置到0，该项将会被移除
      </div>
      <MaterialEditList :items="selectResultList" />
    </van-popup>
  </section>
</template>
<script>
// 故障单服务-选择物料
import basePage from '@/mixins/basePage'
import { Tab, Tabs, List, Search, Sticky, Popup } from 'vant'
import {
  getMaterialTyps,
  getMaterialTypsByGuid,
  getMaterialListByGuid
} from '@/apis/material'
import MaterialEditList from '@/components/material/MaterialEditList'
import EnsureSubmitBar from '@/components/EnsureSubmitBar'
import debounce from '@/utils/debounce'
export default {
  name: 'EsSelectMaterialCountView',
  mixins: [basePage],
  config: {
    fetchDataFn: 'initView'
  },
  components: {
    VanTab: Tab,
    VanTabs: Tabs,
    VanList: List,
    VanPopup: Popup,
    VanSearch: Search,
    VanSticky: Sticky,
    MaterialEditList,
    EnsureSubmitBar
  },
  data() {
    return {
      container: null,
      tabs: [],
      tabActive: 0,
      startWatchTab: false,
      waitForSelectList: [],
      selectResultList: [],
      selectResultMap: {},
      isListLoadingMore: false,
      isListLoadFinished: false,
      isLoadingList: false,
      query: {},
      keyword: '',
      showPopup: false,
      control: null
    }
  },
  watch: {
    tabActive() {
      if (this.startWatchTab === false) return
      window.scrollTo(0, 0)
      this.$nextTick(() => {
        this.query = this.getQuery()
        this.$_fetchDataWidthUi(this.fetchList())
      })
    },
    waitForSelectList: {
      deep: true,
      handler() {
        this.calcSelectResult(new Date().toLocaleString())
      }
    },
    showPopup(val) {
      if (val === false) {
        this.syncWaitForSelectList()
      }
    }
  },
  computed: {
    listFinishedText() {
      return `已加载全部数据-共${this.waitForSelectList.length}条`
    },
    submitBarLabel() {
      return `已选${
        this.selectResultList.length
      }类，${this.selectResultList.reduce((all, item) => {
        return all + item.count
      }, 0)}件`
    }
  },
  methods: {
    onSearch() {
      this.query.keyword = this.keyword
      this.query.pid = 1
      this.$_fetchDataWidthUi(this.fetchList())
    },
    onCancel() {
      if (this.query.keyword) {
        this.query.keyword = ''
        this.query.pid = 1
        this.$_fetchDataWidthUi(this.fetchList())
      }
    },
    calcSelectResult: debounce(function() {
      let selectResultMap = {}
      this.waitForSelectList.forEach(item => {
        // 处理在改到0的操作，
        if (item.count > 0 || this.selectResultMap[item.code]) {
          selectResultMap[item.code] = item.count
        }
      })
      this.syncSelectResult(selectResultMap)
    }, 100),
    syncWaitForSelectList() {
      let newSelectResult = {}
      this.selectResultList.forEach(item => {
        newSelectResult[item.code] = item.count
        // 更新待选择列表
        const target = this.waitForSelectList.find(
          wItem => wItem.code === item.code
        )
        if (target) {
          target.count = item.count
        }
      })
      // 更新选择的结果列表
      this.selectResultList = this.selectResultList.filter(
        item => item.count > 0
      )
      // 更新选中的结果集合
      Object.assign(this.selectResultMap, newSelectResult)
    },
    /** 同步选中的结果集合
     *  @argument resultMap
     */
    syncSelectResult(resultMap) {
      Object.keys(resultMap).forEach(key => {
        if (
          resultMap[key] > 0 &&
          (this.selectResultMap[key] === undefined ||
            this.selectResultMap[key] === 0)
        ) {
          // todo: 加入到列表
          this.syncSelectResultList(key, resultMap[key], 'ADD')
        } else if (resultMap[key] === 0 && this.selectResultMap[key] > 0) {
          // todo: 移除出列表
          this.syncSelectResultList(key, resultMap[key], 'REMOTE')
        } else if (resultMap[key] !== this.selectResultMap[key]) {
          // todo: 同步列表数据
          this.syncSelectResultList(key, resultMap[key], 'SYNC')
        }
      })
      Object.assign(this.selectResultMap, resultMap)
    },
    /**
     * 同步选中的结果列表
     */
    syncSelectResultList(code, count, action) {
      window.console.log(code, count, action)
      let material
      switch (action) {
        case 'ADD':
          material = this.waitForSelectList.find(item => item.code === code)
          this.selectResultList.push({
            ...material
          })
          break
        case 'REMOTE':
          material = this.selectResultList.find(item => item.code === code)
          this.selectResultList.splice(
            this.selectResultList.indexOf(material),
            1
          )
          break
        case 'SYNC':
          material = this.selectResultList.find(item => item.code === code)
          material.count = count
          break
        default:
          break
      }
    },
    getQuery() {
      let params = {}
      try {
        params = JSON.parse(this.$route.query.params)
      } catch (error) {
        window.console.log(error)
      }
      return {
        ...params,
        type: this.tabs[this.tabActive].key,
        pid: 1,
        psize: 20,
        keyword: ''
      }
    },
    // 初始化tab数据，通过判断路由链接有没有指定当前的tab
    async initTabs() {
      if (this.$route.query.test) {
        this.tabs = await this.$_request(getMaterialTyps())
      } else {
        this.tabs = await this.$_request(
          getMaterialTypsByGuid(JSON.parse(this.$route.query.params))
        )
      }
      if (this.$route.query.type) {
        this.tabActive =
          this.tabs.map(item => item.key).indexOf(this.$route.query.type) || 0
      }
      this.$nextTick(() => {
        this.startWatchTab = true
      })
    },
    async fetchList() {
      if (this.isLoadingList) return
      this.isLoadingList = true
      let list = await this.$_request(
        getMaterialListByGuid({
          ...this.query,
          single: 0
        })
      )
      list = list.map(item => {
        item.is_single = 0
        item.count = this.selectResultMap[item.code] || 0
        return item
      })
      if (this.query.pid === 1) {
        this.waitForSelectList = list
      } else {
        this.waitForSelectList.push(...list)
      }
      this.isListLoadFinished = list.length < this.query.psize
      this.isLoadingList = false
    },
    async onListLoadMore() {
      if (this.isListLoadFinished) {
        this.isListLoadingMore = false
        return
      }
      this.query.pid++
      await this.fetchList()
      this.isListLoadingMore = false
    },
    async initView() {
      await this.initTabs()
      this.query = this.getQuery()
      await this.fetchList()
    },
    onSubmit() {
      const control = this.control ? this.control() : null
      control && control.submit(this.selectResultList)
      this.selectResultMap = {}
      this.selectResultList = []
      this.waitForSelectList = this.waitForSelectList.map(item => {
        item.count = 0
        return item
      })
    },
    onClickLabel() {
      this.showPopup = !this.showPopup
    }
  },
  mounted() {
    const { control } = this.$route.params
    this.control = control
  }
}
</script>
<style lang="scss">
.es-select-material-count {
  padding-bottom: 50px;
  .van-tabs__line {
    background-color: $color-blue;
  }
  .pop-tip {
    text-align: center;
    padding-top: 8px;
    color: $color-blue;
  }
}
</style>
