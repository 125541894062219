// 【定损】移除序列号
export function deleteSn(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/delete_sn',
    data
  }
}
// 【定损】添加/编辑定损物料
export function editCode(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/edit_code',
    data
  }
}
// 保存定损物料
export function saveLossSn(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/save_sn',
    data
  }
}
// 检查物料情况
export function checkMaterialInfo(data) {
  return {
    url: '/app/operation/protection/service/loss/scan_code_info',
    data
  }
}
// 返回定损的物料
export function getLossMaterial(data) {
  return {
    url: '/app/operation/protection/service/loss/info',
    data
  }
}
// 提交定损物料
export function commitLossMaterial(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/commit',
    data
  }
}
// 返回物料的所有分类
export function getMaterialTyps() {
  return {
    url: '/app/operation/protection/material/get_types'
  }
}
// 返回物料的所有分类
export function getMaterialTypsByGuid(data) {
  return {
    url: '/app/operation/protection/service/loss/get_select_types',
    data
  }
}
// 获取物料列表
export function getMaterialList(data) {
  return {
    url: '/app/operation/protection/material/get_list',
    data
  }
}
// 获取物料列表
export function getMaterialListByGuid(data) {
  return {
    url: '/app/operation/protection/service/loss/get_select_materials',
    data
  }
}
// 获取飞机上序列号
export function getUavMaterialSn(data) {
  return {
    url: '/app/member/equipment/home/uav_materials',
    data: {
      type: 'UAV',
      is_single: 1,
      is_good: 1,
      ...data
    }
  }
}
// 获取飞机上可能丢失的物料
export function getLossMissMaterial(data) {
  return {
    url: '/app/operation/protection/service/loss/get_lose_miss_materials',
    data: {
      guid: '1D4375FFD581E71CDD1DD771D2CC854F',
      type: 'P20_2017',
      pid: 1,
      psize: 20,
      keyword: '',
      ...data
    }
  }
}

export function saveLossMateImages(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/save_loss_mate_images',
    data: {
      images: data.images,
      loss_mate_guid: data.loss_mate_guid
    }
  }
}
