<template>
  <section class="es-select-material-sn">
    <van-list
      v-model="isListLoadingMore"
      :finished="isListLoadFinished"
      :finished-text="listFinishedText"
      :immediate-check="false"
      @load="onListLoadMore"
    >
      <MaterialSelectSnList
        :list="waitForSelectList"
        :resultMap="selectResultMap"
        @selectAll="onSelectAll"
        @select="onSelect"
      />
    </van-list>
    <EnsureSubmitBar
      :disabled="selectedMaterialCodes.length === 0"
      :label="submitBarLabel"
      @submit="onSubmit"
      @clickLabel="onClickLabel"
    />
    <van-popup
      v-model="showPopup"
      position="bottom"
      :style="{ height: '60%' }"
      round
    >
      <MaterialEditList :items="selectResultList" @sndelete="onSnDelete" />
    </van-popup>
  </section>
</template>
<script>
// 故障单服务-选择物料
import basePage from '@/mixins/basePage'
import { List, Popup } from 'vant'
import { getUavMaterialSn } from '@/apis/material'
import MaterialSelectSnList from '@/components/material/MaterialSelectSnList'
import MaterialEditList from '@/components/material/MaterialEditList'
import EnsureSubmitBar from '@/components/EnsureSubmitBar'
export default {
  name: 'EsSelectMaterialSnView',
  mixins: [basePage],
  components: {
    VanList: List,
    VanPopup: Popup,
    MaterialSelectSnList,
    MaterialEditList,
    EnsureSubmitBar
  },
  data() {
    return {
      waitForSelectList: [],
      selectResultList: [],
      selectResultMap: {},
      isListLoadingMore: false,
      isListLoadFinished: false,
      query: {},
      showPopup: false,
      control: null
    }
  },
  computed: {
    listFinishedText() {
      return `已加载全部数据-共${this.waitForSelectList.length}条`
    },
    // 被选择序列号的物料code的数组
    selectedMaterialCodes() {
      return Object.keys(this.selectResultMap).filter(
        key => this.selectResultMap[key].length > 0
      )
    },
    submitBarLabel() {
      const selectCount = this.selectedMaterialCodes.reduce((total, key) => {
        return total + this.selectResultMap[key].length
      }, 0)
      return `已选${this.selectedMaterialCodes.length}类,${selectCount}件`
    }
  },
  methods: {
    /**
     * 【选择结果集】移除序列号
     */
    onSnDelete(itemIndex, sn) {
      const targetItem = this.selectResultList[itemIndex]
      if (targetItem.serial_numbers.length === 1) {
        this.selectResultList.splice(itemIndex, 1)
        this.selectResultMap[targetItem.code] = []
        this.showPopup = this.selectResultList.length > 0
      } else {
        const snIndex = targetItem.serial_numbers.indexOf(sn)
        targetItem.serial_numbers.splice(snIndex, 1)
        this.selectResultMap[targetItem.code].splice(snIndex, 1)
      }
    },
    /**
     *  【备选列表】点击全选
     */
    onSelectAll(item, index) {
      const { code } = item
      let selectedSns = this.selectResultMap[code]
      let waitSns = Array.from(this.waitForSelectList[index].serial_numbers)
      if (selectedSns.length < waitSns.length) {
        this.selectResultMap[code] = waitSns
      } else {
        this.selectResultMap[code] = []
      }
    },
    /**
     * 【备选列表】点击选择sn
     */
    onSelect(item, sn) {
      const index = this.selectResultMap[item.code].indexOf(sn)
      if (index > -1) {
        this.selectResultMap[item.code].splice(index)
      } else {
        this.selectResultMap[item.code].push(sn)
      }
    },
    /**
     * 获取已被选择的序列号的物料
     */
    getSelectResultList() {
      return this.waitForSelectList
        .filter(item => this.selectResultMap[item.code].length > 0)
        .map(item => {
          return {
            ...item,
            serial_numbers: Array.from(this.selectResultMap[item.code])
          }
        })
    },
    getQuery() {
      return {
        pid: 1,
        psize: 20,
        keyword: ''
      }
    },
    async fetchList() {
      let selectResultMap = {}
      let list = await this.$_request(getUavMaterialSn(this.query))
      list = list.map(item => {
        item.is_single = 1
        selectResultMap[item.code] = this.selectResultMap[item.code] || []
        return item
      })
      if (this.query.pid === 1) {
        this.waitForSelectList = list
        this.selectResultMap = selectResultMap
      } else {
        this.waitForSelectList.push(...list)
        Object.assign(this.selectResultMap, selectResultMap)
      }
      this.isListLoadFinished = list.length < this.query.psize
    },
    async onListLoadMore() {
      if (this.isListLoadFinished) {
        this.isListLoadingMore = false
        return
      }
      this.query.pid++
      await this.fetchList()
      this.isListLoadingMore = false
    },
    async initView() {
      const control = this.control ? this.control() : null
      let baseQuery = control ? control.getQuery() : {}
      this.query = {
        ...baseQuery,
        ...this.getQuery()
      }
      await this.fetchList()
    },
    onSubmit() {
      this.selectResultList = this.getSelectResultList()
      const control = this.control ? this.control() : null
      control && control.submit(this.selectResultList)
      this.reset()
    },
    reset() {
      this.selectResultList = []
      Object.keys(this.selectResultMap).forEach(key => {
        this.selectResultMap[key] = []
      })
    },
    onClickLabel() {
      this.selectResultList = this.getSelectResultList()
      this.showPopup = true
    }
  },
  activated() {
    if (this.waitForSelectList.length === 0) {
      this.$_fetchDataWidthUi(this.initView())
    }
  },
  mounted() {
    const { control } = this.$route.params
    this.control = control
  }
}
</script>
<style lang="scss">
.es-select-material-sn {
  padding-bottom: 50px;
  .van-tabs__line {
    background-color: $color-blue;
  }
  .pop-tip {
    text-align: center;
    padding-top: 8px;
    color: $color-blue;
  }
}
</style>
