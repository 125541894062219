<template>
  <div class="ensure-material-edit__list">
    <div
      v-for="(item, index) in items"
      :key="index + item.code"
      class="xa-line__bottom"
    >
      <materialInputItem v-if="item.is_single == 0" :item="item" />
      <materialSnItem
        v-if="item.is_single == 1"
        :item="item"
        @delete="onSnDelete(index, ...arguments)"
      />
    </div>
    <div class="ensure-material-edit__foot">- 共{{ items.length }}条 -</div>
  </div>
</template>
<script>
import materialInputItem from '@/components/material/MaterialInputItem'
import materialSnItem from '@/components/material/MaterialSnItem'
export default {
  components: {
    materialSnItem,
    materialInputItem
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    onSnDelete(itemIndex, sn) {
      this.$emit('sndelete', itemIndex, sn)
    }
  }
}
</script>
<style lang="scss">
.ensure-material-edit__list {
  .ensure-material-edit__foot {
    text-align: center;
    color: #999;
    font-size: 12px;
    padding: 8px 0;
  }
}
</style>
