<template>
  <section class="es-select-material-sn">
    <van-search
      v-model="keyword"
      placeholder="请输入搜索关键词"
      show-action
      @search="onSearch"
      @cancel="onCancel"
    />
    <van-list
      v-model="isListLoadingMore"
      :finished="isListLoadFinished"
      :finished-text="listFinishedText"
      :immediate-check="false"
      @load="onListLoadMore"
    >
      <div
        class="xa-line__bottom"
        v-for="(item, index) in waitForSelectList"
        :key="item.code + index"
      >
        <MaterialInfo :item="item" @click.native="onClickItem(item)">
          <div v-if="item.is_single == 0" class="xa-arrow"></div>
          <div v-else>
            x{{ item.serial_numbers && item.serial_numbers.length }}
          </div>
        </MaterialInfo>
        <MaterialDisplaySn
          v-if="item.is_single == 1 && item.serial_numbers && item.showSn"
          :items="item.serial_numbers"
          @click="onSelectSn(item, arguments[0])"
        />
      </div>
    </van-list>
  </section>
</template>
<script>
// 故障单服务-选择丢失的物料
import basePage from '@/mixins/basePage'
import request from '@/controllers/request'
import { List, Search } from 'vant'
import { getLossMissMaterial } from '@/apis/material'
import MaterialInfo from '@/components/material/MaterialInfo'
import MaterialDisplaySn from '@/components/material/MaterialDisplaySn'
export default {
  name: 'EsSelectMaterialLossView',
  mixins: [basePage],
  $_fetchDataFn: 'initView',
  components: {
    MaterialInfo,
    MaterialDisplaySn,
    VanList: List,
    VanSearch: Search,
  },
  data() {
    return {
      keyword: '',
      waitForSelectList: [],
      isListLoadingMore: false,
      isListLoadFinished: false,
      query: {},
    }
  },
  computed: {
    listFinishedText() {
      return `已加载全部数据-共${this.waitForSelectList.length}条`
    },
  },
  methods: {
    onSearch(keyword) {
      this.query.keyword = keyword
      this.query.pid = 1
      this.$_fetchDataWidthUi(this.fetchList())
    },
    onCancel() {
      if (this.query.keyword) {
        this.query.keyword = ''
        this.query.pid = 1
        this.$_fetchDataWidthUi(this.fetchList())
      }
    },
    gotoForm(item, sn) {
      window.console.log(JSON.stringify(item), sn)
    },
    onClickItem(item) {
      if (parseInt(item.is_single) === 1) {
        item.showSn = !item.showSn
      } else {
        this.gotoForm(item)
      }
    },
    onSelectSn(item, sn) {
      this.gotoForm(item, sn)
    },
    getQuery() {
      return {
        // type: this.tabs[this.tabActive].key,
        pid: 1,
        psize: 20,
        keyword: '',
      }
    },
    async fetchList() {
      let list = await request(getLossMissMaterial(this.query))
      list = list.map(item => {
        item.showSn = false
        return item
      })
      if (this.query.pid === 1) {
        this.waitForSelectList = list
      } else {
        this.waitForSelectList.push(...list)
      }
      this.isListLoadFinished = list.length < this.query.psize
    },
    async onListLoadMore() {
      if (this.isListLoadFinished) {
        this.isListLoadingMore = false
        return
      }
      this.query.pid++
      await this.fetchList()
      this.isListLoadingMore = false
    },
    async initView() {
      this.query = this.getQuery()
      await this.fetchList()
    },
  },
}
</script>
<style lang="scss">
.es-select-material-sn {
  padding-bottom: 50px;
  .van-tabs__line {
    background-color: $color-blue;
  }
  .pop-tip {
    text-align: center;
    padding-top: 8px;
    color: $color-blue;
  }
}
</style>
