<template>
  <div>
    <MaterialInfo :item="item" @click.native="showAllSn = !showAllSn">
      <div>
        <div>x{{ item.serial_numbers.length }}</div>
        <Icon :name="showAllSn ? 'arrow-up' : 'arrow-down'" />
      </div>
    </MaterialInfo>
    <transition name="van-fade">
      <div class="material-select__wrap" v-if="showAllSn">
        <Tag :plain="calcSelectAll" type="primary" @click="onSelectAll"
          >全选</Tag
        >
        <div class="material-select__box">
          <div v-for="(sn, index) in item.serial_numbers" :key="item + index">
            <Tag
              :plain="result.indexOf(sn) === -1"
              type="primary"
              @click="onSelect(item, sn)"
              >{{ sn }}</Tag
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { Icon, Tag } from 'vant'
import MaterialInfo from '@/components/material/MaterialInfo'
export default {
  components: {
    Icon,
    Tag,
    MaterialInfo
  },
  data() {
    return {
      showAllSn: false
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    result: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    calcSelectAll() {
      return this.result.length !== this.item.serial_numbers.length
    }
  },
  methods: {
    onSelectAll() {
      this.$emit('selectAll')
    },
    onSelect(item, sn) {
      this.$emit('select', item, sn)
    }
  }
}
</script>
<style lang="scss">
.material-select__wrap {
  padding: 10px 0px 0px 15px;
  background-color: $default-page-color--light;
  .van-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .material-select__box {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
