<template>
  <div>
    <div v-for="(item, index) in list" :key="index" class="xa-line__bottom">
      <MaterialSelectSn
        :item="item"
        :result="resultMap[item.code]"
        @selectAll="onSelectAll(item, index)"
        @select="onSelect"
      />
    </div>
  </div>
</template>
<script>
import MaterialSelectSn from '@/components/material/MaterialSelectSn'
export default {
  components: {
    MaterialSelectSn,
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    resultMap: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    onSelectAll() {
      this.$emit('selectAll', ...arguments)
    },
    onSelect() {
      this.$emit('select', ...arguments)
    },
  },
}
</script>
